<template lang="pug">
div.container.mt-5
  div.row.justify-content-md-center
    div.col-sm-6.text-start(
    )
      form.row.g-2(
        @submit.prevent="startSearch"
      )
        div.col-auto
          input.form-control(
            type="text"
            placeholder="Tracking Number"
            v-model="track_number"
          )
        div.col-auto
          button.btn.btn-primary(
            @click="startSearch"
            v-bind:class="getClasses()"
          ) Search
  div.row.justify-content-md-center.mt-2(
    v-if="errorText != ''"
  )
    div.col-sm-6
      div.alert.alert-danger {{errorText}}
  div.row.justify-content-md-center(
    v-if="is_loading"
  )
    div.lds-ellipsis
      div
      div
      div
      div
  div.row.justify-content-md-center.mt-5(
    v-if="search_done && has_results"
  )
    div.col-sm-6
      table.table.table-bordered.table-striped
        thead
          tr
            th &nbsp;
            th(colspan="2") {{searchResults[0].comment}}
          tr
            th Checkpoint / Статус груза
            th Date&amp;Time / Дата и время
            th Comment / Комментарий
        tbody
          tr(
            v-for="(item, idx) in searchResults"
            v-if="idx > 0"
          )
            th.text-start {{item.field}}
            td.text-start(
              style="white-space: nowrap;"
            ) {{item.datetime}}
            td.text-start {{item.comment}}
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      track_number: "",
      is_loading: false,
      search_done: false,
      has_results: false,
      searchResults: [
      ],
      errorText: ""
    }
  },
  methods: {
    startSearch(){
      if (this.track_number == "") {
        this.errorText = "No tracking number specified";
        return false;
      }
    
      this.is_loading = true;
      this.search_done = false;
      this.has_results = false;
      this.errorText = "";
      
      var form_data = new FormData();
      form_data.append('track', this.track_number);
      
      var __this = this;
      axios({
        method: 'post',
        url: '/search.php',
        responseType: 'json',
        data: form_data
      })
        .then(function (response) {
          __this.has_results = response.data.has_results;
          if (__this.has_results == false) {
            __this.errorText = "Tracking number not found!";
          } else {
            __this.errorText = "";
          }
          __this.searchResults = response.data.results;
          __this.search_done = true;
          __this.is_loading = false;
        })
        .catch(function (error) {
          __this.errorText = error.message;
          __this.has_results = false;
          __this.search_done = true;
          __this.is_loading = false;
        });
      
    },
    getClasses() {
      if (this.is_loading) {
        return {"disabled": true};
      } else {
        return null;
      }
    },
  },
}
</script>

<style lang="less">
.cargo-tracker {
  width: 400px;
  margin: 0 auto;
}
</style>