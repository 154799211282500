<template>
  <div class="home mt-5">
    <div class="container text-center">
    <div class="row justify-content-md-center">
    <div class="col-sm-6 text-start">
      <img alt="VD Express" src="../assets/logo.png" />
    </div>
    </div>
    </div>
    <CargoTracker />
  </div>
</template>

<script>
// @ is an alias to /src
import CargoTracker from "@/components/CargoTracker.vue";

export default {
  name: "HomeView",
  components: {
    CargoTracker,
  },
};
</script>
